import { colors } from "./colors"
import * as variants from "./variants"
import * as type from "./type"

const defaultTheme = {
  ...variants,
  ...type,
  colors,
  breakpoints: ["375px", "640px", "768px", "1024px"],
  layout: {
    container: {
      px: 3,
      width: "100%",
      minWidth: 0,
      maxWidth: "1024px",
      mx: "auto",
    },
  },
  styles: {
    root: {
      "*": {
        margin: "0px",
        padding: "0px",
        boxSizing: "border-box",
      },
      body: {
        backgroundColor: "#FBFBFB",
      },
      h1: {
        variant: "text.h1",
      },
      h2: {
        variant: "text.h2",
      },
      h3: {
        variant: "text.h3",
      },
      p: {
        variant: "text.p",
      },
      section: {
        py: ["30px", null, null, "35px", "40px"],
      },
      ul: {
        listStyleType: "disc",
        marginLeft: ["16px", null, null, "24px"],
        marginBottom: "16px",
        color: "text",
      },
      li: {
        marginTop: "2px",
        marginBottom: "2px",
        color: "black",
        variant: "text.p",
      },
    },
  },
}
export default defaultTheme
