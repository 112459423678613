import { colors } from "../colors";

const base = {
	fontFamily: "body",
	fontSize: "19px",
	fontWeight: 500,
	letterSpacing: "0.76px",
	borderRadius: "25px",
	border: "2px solid transparent",
	p: "6px 24px",
	textAlign: "center",
	textDecoration: "none",
	cursor: "pointer",
	transition: "all .2s cubic-bezier(0.25, 1, 0.5, 1)",
};

export const buttons = {
	primary: {
		...base,
		backgroundColor: "white",
		color: "forestGreen",
		borderColor: "forestGreen",
		"&:hover": {
			backgroundColor: "forestGreen",
			color: "white",
			borderColor: "white",
		},
		"&:active": { backgroundColor: "pistachio" },
	},
	filledSm: {
		...base,
		fontSize: "14px",
		backgroundColor: "forestGreen",
		color: "white",
		py: "4px",
		minWidth: "140px",
		boxShadow: "3px 4px 8px #00000080",
		"&:hover": {
			backgroundColor: "moneyGreen",
		},
		"&:active": {
			backgroundColor: "pistachio",
		},
	},
};
