export const colors = {
	pistachio: "#99ca78",
	moneyGreen: "#6DB33F",
	forestGreen: "#004813",
	greenWhite: "#E3E9E7",
	chrome: "#DDE0E5",
  smokeGrey: '#707070',
	iridium: "#3C3B3F",
  darkGrey: '#383739',
	dark: "#231F20",
};
