const fonts: { [k: string]: string } = {
  heading: "Open Sans",
  body: "Open Sans",
}

const fontSizes = [12, 14, 16, 18, 26, 32, 48, 64, 96].map(
  (px) => `${px / 16}rem`
)

const fontWeights = {
  light: 300,
  normal: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
}

export { fonts, fontSizes, fontWeights }
